import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link, graphql } from "gatsby";
import Seo from "../components/seo";
import Helmet from "react-helmet";
import ReactTooltip from "react-tooltip";
import AppleLogo from "../images/apple-logo.svg";
import SwiftLogo from "../images/swift-logo.svg";
import Resume from "../../content/data/resume.json";
import PageLayout from "../components/layouts/longform.js";
import JobList from "../components/resume/job-list.js";
import SkillList from "../components/resume/skill-list.js";
import EducationList from "../components/resume/education-list.js";
import LanguageList from "../components/resume/language-list.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Resume – Software Developer" mdxType="Seo" />
    <Helmet bodyAttributes={{
      class: "resume-page"
    }} mdxType="Helmet" />
    <h1 {...{
      "id": "reda-lemeden--software-developer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#reda-lemeden--software-developer",
        "aria-label": "reda lemeden  software developer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reda Lemeden `}<em parentName="h1">{`/ Software Developer`}</em></h1>
    <h3 {...{
      "id": "apple-platforms--swift--web",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#apple-platforms--swift--web",
        "aria-label": "apple platforms  swift  web permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Apple Platforms • Swift • Web`}</h3>
    <hr></hr>
    <p>{`After getting a `}<abbr title="Bachelor of Business Administration">{`BBA`}</abbr>{` degree in 2007 in my hometown of Casablanca `}<span title="Morocco">{`🇲🇦`}</span>{`,
I moved to Tokyo `}<span title="Japan">{`🇯🇵`}</span>{` to study Japanese,
then Nantes `}<span title="France">{`🇫🇷`}</span>{` to resume my post-graduate studies (`}<abbr title="Master in Management">{`MIM`}</abbr>{`),
then Boston, MA `}<span title="USA">{`🇺🇸`}</span>{` to work as a product designer & developer,
before finally settling in Stockholm `}<span title="Sweden">{`🇸🇪`}</span>{` in 2014.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/kaishin"
      }}>{`GitHub`}</a>{` / `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/reda-lemeden/"
      }}>{`LinkedIn`}</a>{` / `}<a parentName="p" {...{
        "href": "https://dribbble.com/kaishin"
      }}>{`Dribbble`}</a></p>
    <h2 {...{
      "id": "highlights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#highlights",
        "aria-label": "highlights permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Highlights`}</h2>
    <ul>
      <li parentName="ul">{`I started `}<em parentName="li">{`Web development`}</em>{` as a hobby in the early 2000s.`}</li>
      <li parentName="ul">{`After 8 years of wrestling CSS tables in Internet Explorer,
I got inspired by the iPhone to learn `}<em parentName="li">{`mobile development`}</em>{`.`}</li>
      <li parentName="ul">{`In 2010, I wrote `}<em parentName="li">{`my first mobile app`}</em>{` in Objective-C.`}</li>
      <li parentName="ul">{`Today, I enjoy working with various `}<em parentName="li">{`web technologies`}</em>{`,
`}<em parentName="li">{`app development`}</em>{` on `}<img parentName="li" {...{
          "src": "{AppleLogo}",
          "alt": "Apple",
          "className": "inline-icon apple"
        }}></img>{` platforms,
and more recently `}<em parentName="li">{`server-side`}</em>{` `}<img parentName="li" {...{
          "src": "{SwiftLogo}",
          "alt": "Swift",
          "className": "inline-icon swift"
        }}></img>{` Swift.`}</li>
      <li parentName="ul">{`I write about various programming topics on my `}<a parentName="li" {...{
          "href": "https://redalemeden.com/blog"
        }}>{`blog`}</a>{`, as well as a guest author for a handful of `}<a parentName="li" {...{
          "href": "https://nshipster.com/uistackview/"
        }}>{`high`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2012/08/towards-retina-web/"
        }}>{`traffic`}</a>{` `}<a parentName="li" {...{
          "href": "https://thoughtbot.com/blog/authors/reda-lemeden"
        }}>{`websites`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "community--open-source",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#community--open-source",
        "aria-label": "community  open source permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Community & Open Source`}</h2>
    <ul>
      <li parentName="ul">{`In 2012, I open-sourced a `}<a parentName="li" {...{
          "href": "https://github.com/thoughtbot/neat"
        }}>{`Sass grid framework`}</a>{` that gained considerable momentum in the community.`}</li>
      <li parentName="ul">{`Since 2014, I have maintained a handful of open-source iOS and Swift projects on `}<a parentName="li" {...{
          "href": "https://github.com/kaishin"
        }}>{`GitHub`}</a>{`. The latest of which is a `}<a parentName="li" {...{
          "href": "https://github.com/swift-glide/glide"
        }}>{`micro-framework`}</a>{` for server-side development.`}</li>
      <li parentName="ul">{`I’ve organized the `}<a parentName="li" {...{
          "href": "https://www.meetup.com/STHLM-swift/"
        }}>{`STHLM.swift`}</a>{` meetup since 2017.`}</li>
      <li parentName="ul">{`In 2018, I co-organized the first ever Swift conference in the nordics, `}<a parentName="li" {...{
          "href": "https://2018.swiftandfika.com"
        }}>{`Swift & Fika`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "skills",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#skills",
        "aria-label": "skills permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Skills`}</h2>
    <SkillList mdxType="SkillList" />
    <h2 {...{
      "id": "work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#work",
        "aria-label": "work permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Work`}</h2>
    <JobList jobs={Resume.experience} mdxType="JobList" />
    <h2 {...{
      "id": "education",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#education",
        "aria-label": "education permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Education`}</h2>
    <EducationList mdxType="EducationList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      