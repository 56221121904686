import React from "react";
import WorkCard from "./work-card.js";
import { graphql } from "gatsby";

class WorkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <ul className="work-list">
        {this.state.works.map(({ work }, id) => (
          <WorkCard work={work} key={id} />
        ))}
      </ul>
    );
  }
}

export const workDetails = graphql`
  fragment WorkDetails on WorksYaml {
    name
    company
    location
    description
    year
    url
    image {
      name
      extension
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: TRACED_SVG
          layout: CONSTRAINED
        )
      }
    }
    tags
    featured
  }
`;

export default WorkList;
