import { Link, graphql } from "gatsby";
import Seo from "../../../../src/components/seo";
import Helmet from "react-helmet";
import ReactTooltip from "react-tooltip";
import AppleLogo from "../../../../src/images/apple-logo.svg";
import SwiftLogo from "../../../../src/images/swift-logo.svg";
import Resume from "../../../../content/data/resume.json";
import PageLayout from "../../../../src/components/layouts/longform.js";
import JobList from "../../../../src/components/resume/job-list.js";
import SkillList from "../../../../src/components/resume/skill-list.js";
import EducationList from "../../../../src/components/resume/education-list.js";
import LanguageList from "../../../../src/components/resume/language-list.js";
import * as React from 'react';
export default {
  Link,
  graphql,
  Seo,
  Helmet,
  ReactTooltip,
  AppleLogo,
  SwiftLogo,
  Resume,
  PageLayout,
  JobList,
  SkillList,
  EducationList,
  LanguageList,
  React
};