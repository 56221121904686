import React from "react";
import { Link } from "gatsby";

class Navigation extends React.Component {
  render() {
    return (
      <nav className="site-navigation">
        <ol className="navigation-links">
          <li className="link">
            <Link to="/work">Work</Link>
          </li>
          <li className="link">
            <Link to="/projects">Projects</Link>
          </li>
          <li className="link">
            <Link to="/blog">Blog</Link>
          </li>
          <li className="link">
            <Link to="/contact">Contact</Link>
          </li>
        </ol>
      </nav>
    );
  }
}

export default Navigation;
