import React from "react";
import Helmet from "react-helmet";
import WorkList from "../components/work/work-list.js";
import DefaultLayout from "../components/layouts/default.js";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import PageHeader from "../components/page-header";

const WorksPage = ({ data }) => (
  <DefaultLayout>
    <Helmet bodyAttributes={{ class: "works-page" }} />
    <Seo title="Work" />
    <section className="works-section">
      <div className="container">
        <PageHeader title="Work" />
        <WorkList works={data.works.list} />
      </div>
    </section>
  </DefaultLayout>
);

export const query = graphql`
  query Works {
    works: allWorksYaml(limit: 100) {
      list: edges {
        work: node {
          ...WorkDetails
        }
      }
    }
  }
`;

export default WorksPage;
