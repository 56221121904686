import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

class WorkCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.work;
  }

  render() {
    const work = this.state;
    console.log("tags", work);

    return (
      <li className="work-cell">
        <a href={work.url}>
          {work.image.extension === "gif" ? (
            <img
              src={"/gifs/" + work.image.name + ".gif"}
              className="work-image"
              alt={work.name}
            />
          ) : (
            <GatsbyImage
              image={work.image.childImageSharp.gatsbyImageData}
              className="work-image"
              alt={work.name}
            />
          )}
          <div className="work-details">
            <p className="work-year">{work.year}</p>
            <h5 className="work-name">{work.name}</h5>
            <div className="work-caption-container">
              <h5 className="work-caption">{work.company}</h5>
              <p className="work-caption">{work.location}</p>
            </div>
            {/* <p className="work-description">{work.description}</p> */}
            <div className="work-tags-container">
              {work.tags.map((tItem) => (
                <p className="work-tags">{tItem}</p>
              ))}
            </div>
          </div>
        </a>
      </li>
    );
  }
}

export default WorkCard;
