// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-blog-index-js": () => import("./../../../src/components/templates/blog-index.js" /* webpackChunkName: "component---src-components-templates-blog-index-js" */),
  "component---src-components-templates-blog-post-js": () => import("./../../../src/components/templates/blog-post.js" /* webpackChunkName: "component---src-components-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact/thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resume-developer-mdx": () => import("./../../../src/pages/resume/developer.mdx" /* webpackChunkName: "component---src-pages-resume-developer-mdx" */),
  "component---src-pages-work-222-mdx": () => import("./../../../src/pages/work222.mdx" /* webpackChunkName: "component---src-pages-work-222-mdx" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

